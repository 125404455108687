<template>
  <div class="oblige-validation-frais mr-1 ">
    <!-- :class="{ 'mt-2': Array.isArray(this.download) }" -->
    <div
      class="block-icon-popover-facture mr-1"
      :class="{ 'block-icon-table-oblige-facture': iconTable }"
      v-if="visialisation"
      @click.prevent.stop="handleOpenAvoirVusialisationModal()"
      title="Visualisation Facture"
    >
      <font-awesome-icon icon="eye" />
    </div>
    <div
      class=" block-icon-popover-facture mr-1"
      :class="{ 'block-icon-table-oblige-facture': iconTable }"
      v-if="showDownloadButton && download"
      @click.prevent.stop="downloadFactureItem()"
      title="Télecharger Facture"
    >
      <font-awesome-icon icon="arrow-alt-circle-down" />
    </div>
    <div
      class=" block-icon-popover-facture mr-1"
      :class="{
        'red-color-block': IconClass,
        'block-icon-table-oblige-facture': iconTable
      }"
      v-if="show_generate_facture_file_code && value != 0.0"
      @click.prevent.stop="handleEditTemplate"
    >
      <font-awesome-icon icon="file-code" :class="IconClass" />
    </div>

    <div
      v-if="
        download &&
          Array.isArray(download) &&
          download.length > 0 &&
          type2 !== 'avoir' &&
          showDownloadButton
      "
    >
      <font-awesome-icon icon="check-circle" class="icon-style-check-facture" />
    </div>
    <!-- facture to upload  template -->

    <v-dialog
      v-model="uploadFactureTemplateModal"
      max-width="1250px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{
            getTemplateObligeeToUpload && getTemplateObligeeToUpload.name
              ? getTemplateObligeeToUpload.name
              : ''
          }}</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent.stop="hideModal('uploadFactureTemplateModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card mt-2">
          <div
            v-if="
              getChargementAppelPaiementThObligeeFacture == true ||
                loadingUpload == true
            "
            class="loading-custom-reglement-block"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>
          <v-tabs
            align-with-title
            background-color="#f1edfb"
            color="#704ad1"
            v-if="getTemplateObligeeToUpload"
            class="tabs-obligie-validation-fac"
          >
            <v-tab active @click="clickFormTabs" class="tab-item-obligie-fac">
              <span class="tab-text-bold"> Formulaire</span>
            </v-tab>
            <v-tab-item class="tab-item-obligie-fac flex-tab mt-2">
              <div class="col-3 mr-2">
                <!--  fields-input-->
                <div class="mb-2">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="getTemplateObligeeToUpload.date"
                        label="Date de Facture"
                        dense
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        :persistent-placeholder="true"
                        placeholder="Sélectionner un date"
                        prepend-inner-icon="mdi-calendar"
                        class="date-obligie-template-valid"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="getTemplateObligeeToUpload.date"
                      @input="
                        changeFieldsValue({
                          key: 'date_id',
                          value: getTemplateObligeeToUpload.date,
                          e: $event,
                          champName: 'date'
                        })
                      "
                      @focus="
                        focusInFields({
                          key: 'date_id'
                        })
                      "
                      no-title
                      locale="fr"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <!-- payment condition  -->
                <div class="mb-2">
                  <v-select
                    :items="computedConditionPaiement"
                    v-model="getTemplateObligeeToUpload.payment_condition"
                    label="Conditions du paiement"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </div>
                <div class="mb-2">
                  <v-text-field
                    v-model="computedTotalHt"
                    @input="
                      changeFieldsValue({
                        key: 'ht_id',
                        value: getTemplateObligeeToUpload.ht
                      })
                    "
                    @focus="
                      focusInFields({
                        key: 'ht_id'
                      })
                    "
                    required
                    dense
                    label="HT"
                    disabled
                    outlined
                  ></v-text-field>
                </div>
                <div class="mb-2">
                  <v-text-field
                    v-model="computedTotalTva"
                    @input="
                      changeFieldsValue({
                        key: 'tva_id',
                        value: getTemplateObligeeToUpload.tva
                      })
                    "
                    @focus="
                      focusInFields({
                        key: 'tva_id'
                      })
                    "
                    required
                    dense
                    label="TVA"
                    disabled
                    outlined
                  ></v-text-field>
                </div>
                <div class="mb-2">
                  <v-text-field
                    v-model="computedTotalTTC"
                    @input="
                      changeFieldsValue({
                        key: 'ttc_id',
                        value: getTemplateObligeeToUpload.ttc
                      })
                    "
                    @focus="
                      focusInFields({
                        key: 'ttc_id'
                      })
                    "
                    required
                    dense
                    label="TTC"
                    disabled
                    outlined
                  ></v-text-field>
                </div>
                <!-- Rib  -->
                <div class="mb-2">
                  <v-select
                    :items="computedRibs"
                    v-model="getTemplateObligeeToUpload.rib"
                    label="Rib"
                    item-text="name"
                    item-value="id"
                    dense
                    :rules="[v => !!v || 'Rib obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    required
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </div>
              </div>
              <div class="col-9 mt-0">
                <div
                  v-for="(product,
                  index) in getTemplateObligeeToUpload.products"
                  :key="index"
                  class="mt-2"
                >
                  <b-row
                    v-if="
                      computedIndexToSeparate.includes(index) &&
                        type === 'consolidee_energie_invest'
                    "
                    class="separate-type d-flex justify-content-center col-12 mb-1"
                  >
                    <span
                      ><b
                        >--
                        {{ computedFormattedTypeObligee(product.type_obligee) }}

                        --</b
                      ></span
                    >
                  </b-row>
                  <b-row>
                    <template v-if="localProducts !== null">
                      <v-col class="col-2">
                        <v-text-field
                          v-model="localProducts.products[index].nom"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'Désignation obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          v-if="type2 === 'avoir'"
                          label="Désignation"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-1">
                        <v-text-field
                          v-model="localProducts.products[index].reference"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'Réf obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Réf"
                          v-if="type2 === 'avoir'"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-2">
                        <v-text-field
                          v-model="localProducts.products[index].qte"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'Qte obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Réf"
                          v-if="type2 === 'avoir'"
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-2">
                        <v-text-field
                          v-model="localProducts.products[index].pu_ht"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'PU HT obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Réf"
                          v-if="type2 === 'avoir'"
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-2">
                        <v-text-field
                          v-model="localProducts.products[index].total_ht"
                          disabled
                          required
                          :rules="[v => !!v || 'Total HT obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Réf"
                          v-if="type2 === 'avoir'"
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-1">
                        <v-text-field
                          v-model="localProducts.products[index].tva"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'TVA% obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Réf"
                          v-if="type2 === 'avoir'"
                          max="100"
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-2">
                        <v-text-field
                          v-model="localProducts.products[index].total_ttc"
                          disabled
                          required
                          :rules="[v => !!v || 'Total TTC obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Réf"
                          v-if="type2 === 'avoir'"
                          type="number"
                          step="any"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <template>
                      <v-col class="col-2">
                        <v-text-field
                          v-model="product.nom"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'Désignation obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Désignation"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-1">
                        <v-text-field
                          v-model="product.reference"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'Réf obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Réf"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-2">
                        <v-text-field
                          v-model="product.qte"
                          :disabled="product.reste_avoir <= 0"
                          @input="onChangeQtePuHt(product)"
                          required
                          :rules="[v => !!v || 'Qte obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          type="number"
                          label="Qte"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-2">
                        <v-text-field
                          :disabled="product.reste_avoir <= 0"
                          v-model="product.pu_ht"
                          @input="onChangeQtePuHt(product)"
                          required
                          :rules="[v => !!v || 'PU HT obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          type="number"
                          label="PU HT"
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-2">
                        <v-text-field
                          type="number"
                          step="any"
                          v-model="product.total_ht"
                          :max="
                            product.reste_avoir <= 0 ? 0 : product.reste_avoir
                          "
                          required
                          :rules="[v => !!v || 'Total HT obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          disabled
                          label="Total HT"
                        ></v-text-field>
                        <p class="rest-avoir-style" v-if="type2 === 'avoir'">
                          Reste à avoir

                          <template v-if="product.reste_avoir <= 0">0</template>
                          <template v-else>{{ product.reste_avoir }}</template>
                        </p>
                      </v-col>

                      <v-col class="col-1">
                        <v-text-field
                          type="number"
                          @input="onChangeQtePuHt(product)"
                          max="100"
                          step="any"
                          v-model="product.tva"
                          :disabled="type2 === 'avoir'"
                          required
                          :rules="[v => !!v || 'TVA% obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="TVA%"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="col-2"
                        :class="{ 'flex-div-check': type2 === 'avoir' }"
                      >
                        <v-text-field
                          type="number"
                          v-model="product.total_ttc"
                          disabled
                          required
                          :rules="[v => !!v || 'Total TTC obligatoire']"
                          class="msg-error"
                          validate-on-blur
                          dense
                          outlined
                          label="Total TTC"
                        ></v-text-field>
                        <span v-if="type2 === 'avoir'" class="ml-2">
                          <v-checkbox
                            color="#704ad1"
                            v-model="product.checked"
                            switch
                            :disabled="product.reste_avoir <= 0"
                            @change="initQTE(product, index)"
                            hide-details
                          ></v-checkbox>
                        </span>
                      </v-col>
                    </template>

                    <v-textarea
                      class="input-modal-champ col-13 p-0 mt-1 ml-2"
                      v-if="type2 === 'non-avoir'"
                      v-model="product.description"
                      rows="3"
                      required
                      outlined
                      color="#704ad1"
                    ></v-textarea>
                  </b-row>
                  <v-divider class="margin-line"></v-divider>
                </div>
              </div>
            </v-tab-item>
            <v-tab @click="getHtmlTemplate">
              Template
            </v-tab>
            <v-tab-item class="tab-item-obligie-fac mt-2">
              <div
                id="hmt_template_block_id"
                v-if="getTemplateObligeeToUpload"
                v-html="getTemplateObligeeToUpload.template"
              ></div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <div
          v-if="getErrorAppeliPaiementThObligeeFacture"
          class="message-error-modal"
        >
          <ul
            v-if="Array.isArray(getErrorAppeliPaiementThObligeeFacture)"
            class="mb-0"
          >
            <li
              v-for="(e, index) in getErrorAppeliPaiementThObligeeFacture"
              :key="index"
            >
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ getErrorAppeliPaiementThObligeeFacture }}</div>
        </div>
        <div class="message-error-modal" v-if="errorUpload">
          {{ errorUpload }}
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleUpload"
            :disabled="
              !computedDisabledValidateButton ||
                !computedDisabledValidateButton.length
            "
          >
            Valider
          </v-btn>
          <v-btn
            v-if="can_reload === true && type2 === 'non-avoir' && download"
            text
            title="Actualiser la facture"
            @click="handleRefreche"
          >
            <font-awesome-icon icon="retweet"
          /></v-btn>
          <v-btn text @click="hideModal('uploadFactureTemplateModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal visualisation -->
    <v-dialog
      v-model="visualisationFacture"
      max-width="900px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Visualisation Facture</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal('visualisationFacture')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2" v-if="body">
          <div id="hmt_template_block_id" v-html="body"></div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn @click="hideModal('visualisationFacture')" text>Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Rapport Création Facture Avoir -->
    <v-dialog
      v-model="recapModal"
      max-width="900px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Rapport Création Facture Avoir
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal('recapModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <v-row v-if="rapport != null" ref="recapFactureAvoir">
            <v-col
              sm="12"
              :md="
                rapport && rapport.updated && rapport.updated.length ? 6 : 12
              "
              v-if="rapport && rapport.created.length"
            >
              <Card>
                <template v-slot:body>
                  <b style="color: black"
                    >Facture(s) ajoutée(s) avec succès ({{
                      rapport.created.length
                    }}):
                  </b>
                  <b-table-simple
                    class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                    responsive
                  >
                    <b-tbody>
                      <b-tr class="title-ligne">
                        <b-th class="newcolor">Société</b-th>
                        <b-th class="newcolor">Client</b-th>
                        <b-th class="newcolor">Numero Facure</b-th>
                        <b-th class="newcolor">Montant</b-th>
                        <b-th class="newcolor">Nombre des produits</b-th>
                        <b-th class="newcolor">Coefficient</b-th>
                        <b-th class="newcolor">Objet</b-th>
                        <b-th class="newcolor">Template</b-th>
                      </b-tr>
                      <b-tr
                        v-for="(item, index) in rapport.created"
                        v-bind:key="index"
                      >
                        <b-td>{{ item.vendeur }}</b-td>
                        <b-td>{{ item.client }}</b-td>
                        <b-td>{{ item.num }}</b-td>
                        <b-td>{{ item.montant_ttc }} €</b-td>
                        <b-td>{{ item.number_of_products }} </b-td>
                        <b-td>{{ item.coef }} </b-td>
                        <b-td>{{ item.objet }} </b-td>
                        <b-td>{{ item.template }} </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </template>
              </Card>
            </v-col>
            <v-col
              sm="12"
              :md="
                rapport && rapport.created && rapport.created.length ? 6 : 12
              "
              v-if="rapport && rapport.updated.length"
            >
              <Card>
                <template v-slot:body>
                  <b style="color: black"
                    >Facture(s) modifiée(s) ({{ rapport.updated.length }}) :</b
                  >
                  <b-table-simple
                    class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                    responsive
                  >
                    <b-tbody>
                      <b-tr class="title-ligne">
                        <b-th class="newcolor">Société</b-th>
                        <b-th class="newcolor">Client</b-th>
                        <b-th class="newcolor">Numero Facure</b-th>
                        <b-th class="newcolor">Montant</b-th>
                        <b-th class="newcolor">Nombre des produits</b-th>
                        <b-th class="newcolor">Coefficient</b-th>
                        <b-th class="newcolor">Objet</b-th>
                        <b-th class="newcolor">Template</b-th>
                      </b-tr>
                      <b-tr
                        v-for="(item, index) in rapport.updated"
                        v-bind:key="index"
                      >
                        <b-td>{{ item.vendeur }}</b-td>
                        <b-td>{{ item.client }}</b-td>
                        <b-td>{{ item.num }}</b-td>
                        <b-td>{{ item.montant_ttc }} €</b-td>
                        <b-td>{{ item.number_of_products }} </b-td>
                        <b-td>{{ item.coef }} </b-td>
                        <b-td>{{ item.objet }} </b-td>
                        <b-td>{{ item.template }} </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </template>
              </Card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              sm="12"
              :md="
                rapport &&
                rapport.locked_factures &&
                rapport.locked_factures.length
                  ? 6
                  : 12
              "
              v-if="rapport && rapport.proforma_to_facture.length"
            >
              <Card>
                <template v-slot:body>
                  <b style="color: black"
                    >Proforma transfère à facture ({{
                      rapport.proforma_to_facture.length
                    }}) :</b
                  >
                  <b-table-simple
                    class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                    responsive
                  >
                    <b-tbody>
                      <b-tr class="title-ligne">
                        <b-th class="newcolor">Societe</b-th>
                        <b-th class="newcolor">Client</b-th>
                        <b-th class="newcolor">Numero Facure</b-th>
                        <b-th class="newcolor">Montant</b-th>
                        <b-th class="newcolor">Numéro proforma</b-th>
                      </b-tr>
                      <b-tr
                        v-for="(item, index) in rapport.proforma_to_facture"
                        v-bind:key="index"
                      >
                        <b-td>{{ item.vendeur }}</b-td>
                        <b-td>{{ item.client }}</b-td>
                        <b-td>{{ item.num }}</b-td>
                        <b-td>{{ item.montant_ttc }} €</b-td>
                        <b-td>{{ item.num_proforma }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </template>
              </Card>
            </v-col>
            <v-col
              v-if="rapport && rapport.locked_factures.length"
              sm="12"
              :md="
                rapport &&
                rapport.proforma_to_facture &&
                rapport.proforma_to_facture.length
                  ? 6
                  : 12
              "
            >
              <Card>
                <template v-slot:body>
                  <b style="color: black"
                    >Facture(s) verrouille(s)({{
                      rapport.locked_factures.length
                    }}) :</b
                  >
                  <b-table-simple
                    class="table-fiche custom-table-style mb-0 d-flex justify-content-center"
                    responsive
                  >
                    <b-tbody>
                      <b-tr class="title-ligne">
                        <b-th class="newcolor">Numero Facure</b-th>
                        <b-th class="newcolor">Montant</b-th>
                      </b-tr>
                      <b-tr
                        v-for="(item, index) in rapport.locked_factures"
                        v-bind:key="index"
                      >
                        <b-td>{{ item.num }}</b-td>
                        <b-td>{{ item.ttc }} €</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </template>
              </Card>
            </v-col>
          </v-row>
          <v-row class="mt-4" v-if="rapport != null">
            <v-col
              sm="12"
              :md="rapport && rapport.no_seq && rapport.no_seq.length ? 6 : 12"
              v-if="rapport && rapport.no_rib.length"
            >
              <Card>
                <template v-slot:body>
                  <b style="color: black"
                    >Société sans RIB ({{ rapport.no_rib.length }}):</b
                  >
                  <ul style="list-style-type: none">
                    <li
                      v-for="(item, index) in rapport.no_rib"
                      v-bind:key="index"
                      style="color: black"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </template>
              </Card>
            </v-col>
            <v-col
              sm="12"
              :md="rapport && rapport.no_rib && rapport.no_rib.length ? 6 : 12"
              v-if="rapport && rapport.no_seq.length"
            >
              <Card>
                <template v-slot:body>
                  <b style="color: black"
                    >Société sans séquance ({{ rapport.no_seq.length }}):</b
                  >
                  <ul style="list-style-type: none">
                    <li
                      v-for="(item, index) in rapport.no_seq"
                      v-bind:key="index"
                      style="color: black"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </template>
              </Card>
            </v-col>
          </v-row>
          <v-row class="mt-4" v-if="rapport != null">
            <v-col sm="12" ms="12" v-if="rapport && rapport.no_changes"
              ><Card>
                <template v-slot:body
                  ><b style="color: black; padding-top:5px ">{{
                    rapport.no_changes
                  }}</b></template
                ></Card
              ></v-col
            ></v-row
          >
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <b-button
            size="sm"
            variant="light"
            class="button-default-style ml-2 export-facture-auto"
            title="Télecharger  cette contenue"
            @click.prevent="downloadContentAutoFacture()"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
          <v-btn @click="hideModal('recapModal')" text>Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import moment from 'moment';
// import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    item: { required: true },
    type: { required: true },
    type2: { required: false },
    updateFunction: { required: true },
    getTemplateFunction: { required: true },
    download: { required: true },
    showDownloadButton: { required: true },
    value: { require: true },
    can_reload: { required: true },
    refereshTemplateFunction: { required: true },
    show_generate_facture_file_code: { default: true },
    downloadFactureApi: { required: false },
    visialisation: { default: false },
    IconClass: { default: '' },
    iconTable: { required: false },
    body: {
      required: false
    },
    filter: {
      required: false
    }
  },
  data() {
    return {
      loadingUpload: false,
      errorUpload: null,
      activeFormTab: true,
      activeTemplateTab: false,
      localProducts: null,
      typeToSend: null,
      rapport: null,
      visualisationFacture: false,
      uploadFactureTemplateModal: false,
      recapModal: false
    };
  },
  methods: {
    ...mapActions([
      'downloadFactureObligeeItem',
      'downloadMultiAvoirPdfs',
      'downloadPaiementObligeeThFactureFiltredPdf',
      'getAllConditionsPaiement'
    ]),
    downloadContentAutoFacture() {
      html2pdf(this.$refs.recapFactureAvoir, {
        margin: 0.5,
        filename:
          'Rapport' + moment(Date.now()).format('YYYY-MM-DD') + 'oblige.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'legal', orientation: 'landscape' }
      });
    },
    async handleOpenAvoirVusialisationModal() {
      this.visualisationFacture = true;
      this.$emit('returnHistorique', true);
    },
    initQTE(product, index) {
      if (product.checked === false) {
        product.qte = 0;
        product.pu_ht = 0;
        product.total_ht = 0;
        product.total_ttc = 0;
        product.tva = 0;
      } else {
        product.qte = this.localProducts.products[index].qte;
        product.pu_ht = this.localProducts.products[index].pu_ht;
        product.total_ht = this.localProducts.products[index].total_ht;
        product.total_ttc = this.localProducts.products[index].total_ttc;
        product.tva = this.localProducts.products[index].tva;
      }
    },
    onChangeQtePuHt(product) {
      product.total_ht =
        Math.round(
          parseFloat((product.qte + '').replace(',', '.') || 0) *
            parseFloat((product.pu_ht + '').replace(',', '.') || 0) *
            100
        ) / 100;

      let tva =
        Math.round(
          ((parseFloat((product.total_ht + '').replace(',', '.') || 0) *
            parseFloat((product.tva + '').replace(',', '.') || 0)) /
            100) *
            100
        ) / 100;

      product.total_ttc =
        Math.round(
          (parseFloat((product.total_ht + '').replace(',', '.') || 0) + tva) *
            100
        ) / 100;
    },
    async handleRefreche() {
      if (this.value != '0.00') {
        const response = await this.refereshTemplateFunction({
          lot: this.item.name,
          type: this.type,
          id_facture: this.download
        });
        if (response) {
          if (this.getTemplateObligeeToUpload.added > 0) {
            let htmlMessage =
              "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";
            for (
              let i = 0;
              i < this.getTemplateObligeeToUpload.added_projects.length;
              i++
            ) {
              htmlMessage =
                htmlMessage +
                '<li>' +
                this.getTemplateObligeeToUpload.added_projects[i] +
                '</li>';
            }

            htmlMessage = htmlMessage + '</div></div>';
            this.$swal.fire({
              background: 'rgb(245 245 252)',
              title:
                this.getTemplateObligeeToUpload.added +
                ' dossier(s) est ajouté à la facture',
              icon: 'warning',
              locale: 'fr',
              showLoaderOnConfirm: true,
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: true,
              html: htmlMessage,
              confirmButtonText: 'Ok',
              cancelButtonClass: 'btn btn-danger',
              cancelButtonColor: '#d33',
              confirmButtonColor: '#43af60',
              customClass: {
                actions: 'my-actions',
                cancelButton: 'order-2 ',
                confirmButton: 'order-1'
              }
            });
          }
          this.localProducts = JSON.parse(
            JSON.stringify(this.getTemplateObligeeToUpload)
          );
          let now = new Date();
          let dateFormate = moment(now).format('DD-MM-YYYY');
          if (
            (this.getTemplateObligeeToUpload &&
              this.getTemplateObligeeToUpload.date == null) ||
            this.getTemplateObligeeToUpload.date == ''
          ) {
            this.getTemplateObligeeToUpload.date = dateFormate;
          }
          if (
            this.getTemplateObligeeToUpload.rib == null ||
            this.getTemplateObligeeToUpload.rib == ''
          ) {
            this.getTemplateObligeeToUpload.vendeur_ribs.map(item => {
              if (item.favori === true) {
                this.getTemplateObligeeToUpload.rib = item.rib;
              }
            });
          }
          this.getTemplateObligeeToUpload?.products?.map((item, index) => {
            if (item.reste_avoir <= 0) {
              this.getTemplateObligeeToUpload.products[index].qte = 0;
              this.getTemplateObligeeToUpload.products[index].pu_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ttc = 0;
              this.getTemplateObligeeToUpload.products[index].tva = 0;
            }
          });
        }
      }
    },
    async handleEditTemplate() {
      if (this.value != '0.00') {
        // this.$emit('returnHistorique', true);
        this.uploadFactureTemplateModal = true;
        this.getAllConditionsPaiement();
        let params = {};

        if (Array.isArray(this.download)) {
          params = {
            lot: this.item.name,
            type: this.type == 'avoir' ? 'missing_prime' : this.type,
            av: this.type2 == 'avoir' ? 'oui' : 'no',
            vendeur:
              this.type == 'prime' ||
              this.type == 'avoir' ||
              this.type == 'missing_prime'
                ? this.item.id_vendeur
                : this.item.id_group,
            acheteur: this.item.id_acheteur,
            item: this.item,
            av: this.type2 == 'avoir' ? 'oui' : 'no'
          };
        } else {
          params = {
            id: this.download,
            av: this.type2 == 'avoir' ? 'oui' : 'no',
            type: this.type == 'avoir' ? 'missing_prime' : this.type
          };
        }
        const response = await this.getTemplateFunction(params);
        if (response) {
          this.localProducts = JSON.parse(
            JSON.stringify(this.getTemplateObligeeToUpload)
          );
          let now = new Date();
          let dateFormate = moment(now).format('DD-MM-YYYY');
          if (
            this.getTemplateObligeeToUpload.date == null ||
            this.getTemplateObligeeToUpload.date == ''
          ) {
            this.getTemplateObligeeToUpload.date = dateFormate;
          }
          if (
            this.getTemplateObligeeToUpload.rib == null ||
            this.getTemplateObligeeToUpload.rib == ''
          ) {
            this.getTemplateObligeeToUpload.vendeur_ribs.map(item => {
              if (item.favori === true) {
                this.getTemplateObligeeToUpload.rib = item.rib;
              }
            });
          }
          this.getTemplateObligeeToUpload?.products?.map((item, index) => {
            if (item.reste_avoir <= 0) {
              this.getTemplateObligeeToUpload.products[index].qte = 0;
              this.getTemplateObligeeToUpload.products[index].pu_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ht = 0;
              this.getTemplateObligeeToUpload.products[index].total_ttc = 0;
              this.getTemplateObligeeToUpload.products[index].tva = 0;
            }
          });
        }
        // }
      }
    },
    async handleUpload() {
      if (
        this.getTemplateObligeeToUpload &&
        this.getTemplateObligeeToUpload.rib != null
      ) {
        this.loadingUpload = true;
        this.errorUpload = null;
        let rib_tables = this.getTemplateObligeeToUpload.vendeur_ribs.filter(
          item => item.rib === this.getTemplateObligeeToUpload.rib
        );
        let data = {
          type: this.type == 'avoir' ? 'missing_prime' : this.type,
          av: this.type2 == 'avoir' ? 'oui' : 'no',
          ht: this.computedTotalHt,
          tva: this.computedTotalTva,
          ttc: this.computedTotalTTC,
          swift: rib_tables[0]?.swift,
          iban: rib_tables[0]?.iban,
          item: this.item,
          date: this.getTemplateObligeeToUpload.date,
          products: this.getTemplateObligeeToUpload.products,
          id_rib: rib_tables[0]?.id,
          payment_condition: this.getTemplateObligeeToUpload.payment_condition
        };
        if (this.type2 == 'avoir') {
          data.date_debut = this.filter.date_debut;
          data.date_fin = this.filter.date_fin;
        }
        if (Array.isArray(this.download)) {
          data.lot = this.item.name;
          data.vendeur =
            this.type == 'prime' ||
            this.type == 'avoir' ||
            this.type == 'missing_prime'
              ? this.item.id_vendeur
              : this.item.id_group;
          data.acheteur = this.item.id_acheteur;
        } else {
          data.id = this.download;
        }
        const response = await this.updateFunction(data);

        if (response.succes) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
          if (this.type2 == 'avoir' && response.rapport) {
            this.rapport = response.rapport;
            this.$emit('returnHistorique', true);
            this.recapModal = true;
          }
        } else {
          this.errorUpload = response.error;
          this.loadingUpload = false;
        }
      } else {
        this.errorUpload =
          'Une erreur est servenue ! IBAN et SWIFT obligatoire';
      }
    },
    clickFormTabs() {
      this.activeFormTab = true;
      this.activeTemplateTab = false;
    },
    async getHtmlTemplate() {
      this.activeFormTab = false;
      this.activeTemplateTab = true;
      if (this.value != '0.00') {
        this.uploadFactureTemplateModal = true;
        var paramsTemplate = {
          p:
            this.type2 == 'avoir'
              ? this.getTemplateObligeeToUpload.products.filter(
                  i => i.checked == true
                )
              : this.getTemplateObligeeToUpload.products,
          type: this.type == 'avoir' ? 'missing_prime' : this.type
        };
        paramsTemplate.av = this.type2 == 'avoir' ? 'oui' : 'no';
        if (Array.isArray(this.download)) {
          paramsTemplate.lot = this.item.name;
          paramsTemplate.type =
            this.type == 'avoir' ? 'missing_prime' : this.type;
          paramsTemplate.av = this.type2 == 'avoir' ? 'oui' : 'no';
          paramsTemplate.vendeur =
            this.type == 'prime' ||
            this.type == 'avoir' ||
            this.type == 'missing_prime'
              ? this.item.id_vendeur
              : this.item.id_group;
          paramsTemplate.acheteur = this.item.id_acheteur;
          paramsTemplate.item = this.item;
          paramsTemplate.payment_condition = this.getTemplateObligeeToUpload.payment_condition;
          paramsTemplate.date = this.getTemplateObligeeToUpload.date;
          paramsTemplate.updatePdf = 'yes';
        } else {
          paramsTemplate.id = this.download;
          paramsTemplate.item = this.item;
          paramsTemplate.payment_condition = this.getTemplateObligeeToUpload.payment_condition;
          paramsTemplate.date = this.getTemplateObligeeToUpload.date;
          paramsTemplate.updatePdf = 'yes';
        }
        const response = await this.getTemplateFunction(paramsTemplate);
        if (response) {
        }
      }
    },
    changeFieldsValue(item) {
      if (item.key == 'tva_id') {
        if (
          this.getTemplateObligeeToUpload.tva != null &&
          this.getTemplateObligeeToUpload.tva != ''
        ) {
          let tvaCoef = Number.parseFloat(
            (this.getTemplateObligeeToUpload.tva + '')
              .replace(' ', '')
              .replace(',', '.')
          );

          let value = Number.parseFloat(
            (document.getElementById('total_ht_id').innerText + '')
              .replace(' ', '')
              .replace(',', '.')
          );

          document.getElementById('total_tva_id').innerHTML = (
            (value / 100) *
            tvaCoef
          ).toFixed(2);
          var elms = document.querySelectorAll('[id=total_ttc_id]');
          for (var i = 0; i < elms.length; i++) {
            elms[i].innerHTML = ((value / 100) * tvaCoef + value).toFixed(2);
          }
        }
      }
      this[item.champName] = item.e;
      if (item && item.e) {
        this.getTemplateObligeeToUpload[item.champName] = item.e;
        document.getElementById(item.key).innerHTML = item.e;
      } else {
        document.getElementById(item.key).innerHTML = item.value;
      }
    },
    focusInFields(item) {
      var myElement = document.getElementById(item.key);
      var rect = myElement.getBoundingClientRect();
      document.getElementById('hmt_template_block_id').scrollTop =
        rect.top + window.pageYOffset;
    },
    resetModal(ref) {
      this.loadingUpload = false;
      this.errorUpload = null;
      if (ref == 'recapModal') {
        this.rapport = null;
      }
    },
    hideModal(ref) {
      this[ref] = false;
      this.$emit('returnHistorique', false);
      this.resetModal(ref);
    },
    downloadFactureItem() {
      let params = {};
      if (Array.isArray(this.download)) {
        params.ids = this.download;
        params.type = [this.type];
      } else {
        params.id = this.download;
      }
      this.downloadFactureApi(params);
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateObligeeToUpload',
      'getChargementAppelPaiementThObligeeFacture',
      'getErrorAppeliPaiementThObligeeFacture',
      'getFraisVisionFilialeData',
      'checkPermission',
      'ConditionPaiement'
    ]),
    computedFormattedTypeObligee() {
      return function(type_obligee) {
        if (this.type === 'consolidee_energie_invest') {
          switch (type_obligee) {
            case 'commission':
              return 'Commission';
            case 'surprime':
              return 'Surprime';
            case 'commission_gestion':
              return 'Commission gestion';
            default:
              return null;
          }
        }
      };
    },
    computedIndexToSeparate() {
      let separateIndexs = [];
      separateIndexs.push(0);
      const sizeProductesArray = this.getTemplateObligeeToUpload.products
        .length;
      for (let i = 1; i < sizeProductesArray; i++) {
        if (
          this.getTemplateObligeeToUpload?.products[i]?.type_obligee !==
          this.getTemplateObligeeToUpload?.products[i - 1]?.type_obligee
        ) {
          separateIndexs.push(i);
        }
      }
      return separateIndexs;
    },
    computedDisabledValidateButton() {
      let tab = [];
      if (
        this.getTemplateObligeeToUpload &&
        this.getTemplateObligeeToUpload.products &&
        this.getTemplateObligeeToUpload.products.length
      ) {
        tab = this.getTemplateObligeeToUpload.products.filter(
          item => item.reste_avoir !== 0 && item.checked === true
        );
      }
      return tab;
    },
    computedTotalHt() {
      let sum = 0;
      this.getTemplateObligeeToUpload?.products?.map(item => {
        sum += parseFloat((item.total_ht + '').replace(',', '.') || 0);
      });
      return Math.round(sum * 100) / 100;
    },
    computedTotalTva() {
      let sum = 0;
      this.getTemplateObligeeToUpload?.products?.map(item => {
        sum +=
          (parseFloat((item.total_ht + '').replace(',', '.') || 0) *
            parseFloat((item.tva + '').replace(',', '.') || 0)) /
          100;
      });
      return Math.round(sum * 100) / 100;
    },
    computedTotalTTC() {
      let sum = 0;
      this.getTemplateObligeeToUpload?.products?.map(item => {
        sum += parseFloat((item.total_ttc + '').replace(',', '.') || 0);
      });
      return Math.round(sum * 100) / 100;
    },
    computedRibs() {
      return this.getTemplateObligeeToUpload?.ribs?.map(item => {
        return item.rib;
      });
    },
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          name: item.name,
          id: item.id
        };
      });
      return table;
    }
  },
  components: {
    // DatePicker,
    Card: () => import('@/views/component/card.vue')
  }
};
</script>

<style lang="scss" scoped>
.margin-line {
  margin-top: 25px;
}
.flex-tab {
  display: flex;
}
.separate-type {
  background-color: #ffff;
  padding: 2px 20px;
  color: #704ad1;
  border: 1px solid;
  margin: auto;
}
.rest-avoir-style {
  color: red;
  font-size: 10px;
  margin-bottom: 0px;
}
.refrech-button-style {
  border-radius: 30px;
  background-color: #9799d6;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.delete-icon-style {
  color: red;
}
.oblige-validation-frais {
  display: flex;
  align-items: center;
}
.icon-time-position {
  float: right;
}
.oblige-template-block-style {
  .input-fields-style-for-template {
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 113px);
    .fields-input {
      display: inline;
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 113px);
    padding-right: 6px;
  }
}
.icon-plus {
  font-size: 13px;
  color: #8d8cb7;
  &:hover {
    color: #704ad1;
  }
}
.icon-style-color:disabled,
.icon-style-color[disabled] {
  background-color: #cccccc;
  color: #b1b1b1;
}
.is-active-button {
  background-color: #704ad1;
  color: #fff;
}
.separate-line {
  border: 1px solid #ccc;
}
.active-tab-style {
  background-color: #7978aa !important;
  color: #fff !important;
}
.ht-disabled {
  pointer-events: none;
  background-color: #e9ecef;
}
.flex-div-check {
  align-items: baseline;
  margin-top: -19px;
  display: flex;
}
</style>
<style lang="scss">
.date-obligie-template-valid {
  .v-input__prepend-inner {
    margin-top: 14px !important;
    font-size: 23px !important;
  }
}
</style>
